.calendar-modal {

    .left-panel {
        width: 204px;
    }

    .ant-modal-body {
        height: calc(100% - 55px);

        .rc-calendar-full {
            margin: 0 !important;
        }
    }
}
