@secondaryDark: #2a2f35;

@import '~antd/dist/antd.less';

@font-face {
    font-family: 'Uni Sans Bold';
    src: url('../fonts/Uni Sans Bold.ttf') format('truetype');
}

@import './forms/forms.less';
@import './login/login.less';

@import './modals/calendars.less';
@import './modals/projects.less';
@import './modals/custom-fields.less';

@import './vendor/gantt.less';
// @import './vendor/scheduler.less';
@import './vendor/ant/tree.less';

@import './vendor/simple-react-calendar.less';
@import '~rc-calendar/assets/index.css';
@import '~rc-select/assets/index.less';

body {
    font-family: Lato, sans-serif;
}

.page-wrapper {
    padding: 20px;
}
.page-sider {
    // padding-right: 20px;
}

.activities-wrapper {
    background-color: #fff;
    width: 100%;
    height: calc(100vh - 180px);
}

.app-menu-dropdown {
    width: 100%;
    height: 44px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: @secondaryDark;
    border: 1px solid #f8f8f8;
    box-shadow: inset -2px -3px 3px rgba(130, 130, 130, 0.15);
    border-radius: 4px;
    transition: background-color, color;
    transition-duration: 0.4s;

    &:hover,
    &:focus,
    &:visited {
        color: white;
    }

    .selected-menu-title {
        width: 184px;
        display: flex;
        justify-content: left;
        align-items: center;
    }

    .selected-menu-bg {
        display: inline-flex;
        flex: 0 0 28px;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 28px;
        font-size: 18px;
        background-color: #fbbe3f;
        color: white;
        margin-right: 4px;
        transition: background-color, color;
        transition-duration: 0.4s;
    }

    &.ant-dropdown-open {
        background-color: #fbbe3f;
        color: white;

        .selected-menu-bg {
            background-color: white;
            color: #fbbe3f;
        }
    }
}

.app-menu-menu {
    .ant-dropdown-menu-item {
        padding: 10px 15px;
        color: @secondaryDark;
        font-size: 15px;

        svg {
            margin-right: 10px;
            font-size: 18px;
            color: #fbbe3f;
        }
    }
}

.ant-table-thead {
    & > tr > th {
        background: @secondaryDark !important;
        font-size: 14px;
        color: white;
        padding: 8px;
    }
}

.ant-btn-primary {
    color: @secondaryDark;

    &:hover,
    &:focus {
        color: @secondaryDark;
    }
}

.ant-modal {
    .ant-modal-content {
        height: 100%;

        .ant-modal-title {
            font-family: 'Uni Sans Bold', sans-serif;
            font-size: 18px;
        }
    }
}

.ant-popconfirm {
    z-index: 1100;
}

.ant-collapse-header {
    padding: 8px 12px !important;
} 

.ant-collapse-arrow {
    vertical-align: 2px !important;
} 

.activity-modification .ant-collapse-header {
    padding-left: 0px !important;
    padding-bottom: 0px;
    color: #fbbe3f !important;
    font-weight: bold;
    font-family: 'Uni Sans', sans-serif;
    display: flex;
    align-items: center;
    font-size: 15px;
}
// .activity-modification .ant-collapse-arrow {
//     vertical-align: 2px !important;
// } 

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    margin-left: 0 !important;
    content: unset;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
}

.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-right: 0;
}

.ant-popover-title {
    font-weight: bold !important;
}

.gantt_task_line.box-shadow {
    box-shadow: 0 0 0 1px black !important;
}

.ant-table-thead th.ant-table-column-has-sorters:hover,
.ant-table-thead th.ant-table-column-sort {
    background: rgba(42, 47, 53, 1);
}

tr.drop-over-downward td {
    border-bottom: 3px dashed #ffd469;
}
tr.drop-over-upward td {
    border-top: 3px dashed #ffd469;
}

.ant-spin-full .ant-spin {
    max-height: 100% !important;
}

.active-button:hover,
.active-button:focus,
.active-button.active {
    color: rgba(251, 190, 63, 1);
    background: rgba(0, 0, 0, 0.9);
    border-color: transparent;
}

.tree-actions {
    display: none;
}
.ant-tree-node-selected  .tree-actions {
    display: block;
}

.animate-background {
    background-size: 110% 110%;
    background-position: center center;
    animation: shrink 20s infinite alternate;
}

.ant-card-bordered {
    border: 1px solid #d9d9d9;
}
@keyframes shrink {
    0% {
        background-size: 110% 110%;
    }
    100% {
        background-size: 100% 100%;
    }
}

.queryBuilder .ruleGroup[data-path="[]"] .ruleGroup-combinators:first-child {
    display: none !important;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

// Define the number of levels you want
@total-levels: 25;

// Loop to generate classes and apply styles
.generate-level-classes(@index) when (@index >= 0) {
  .ant-table-row-level-@{index} {
    background: rgba(0, 0, 0, (@index / @total-levels));
    color: black;
  }

  // Continue the loop
  .generate-level-classes(@index - 1);
}

.needs-table {
    // Call the loop with the total number of levels
    .generate-level-classes(@total-levels);
}

.task-import-table {
    .ant-table-sticky-holder {
        overflow: unset !important;
    }
    // .ant-table-thead > tr:first-child > th:first-child {
    //     background: white !important;
    // }
    .ant-table-cell {
        padding: 0px !important;
    }
    .ant-table-sticky-scroll {
        display: none;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: #fff3a1 !important;
    }

    .ant-table-thead > tr > th {
        padding: 8px !important;
    }
}

@primary-color: #fbbe3f;@text-color: #2a2f35;@border-color-base: #d9d9d9;@border-color-split: #d9d9d9;