h1 {
    font-family: "Uni Sans Bold", sans-serif;
    font-weight: 700 !important;
    font-size: 22px !important;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px !important;
}

.form-description {
    font-family: 'Lato', 'sans-serif';
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #2A2F35;
}

.login-form {
    max-width: 216px;

    .ant-input {
        padding: 8px 16px;
        height: 40px;
        background: #f8f8f8;
        box-shadow: inset 2px 3px 3px rgba(130, 130, 130, 0.15);
        border-radius: 4px;

        &.password-input {
            padding-right: 36px;
        }
    }
}
