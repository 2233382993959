label {
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    // font-family: 'Uni Sans Bold', sans-serif;
}

.ant-form-vertical {
    .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
        padding-bottom: 0;
    }
}

.ant-form-item-explain, .ant-form-item-extra {
    font-size: 12px;
}
