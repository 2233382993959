// Basic
$rqb-spacing: 0.5rem !default;
$rqb-background-color: rgba(251, 190, 63, 0.2) !default;
$rqb-border-color: #000000 !default;
$rqb-border-style: solid !default;
$rqb-border-radius: 4px !default;
$rqb-border-width: 1px !default;

// Drag-and-drop
$rqb-dnd-hover-border-bottom-color: rebeccapurple !default;
$rqb-dnd-hover-copy-border-bottom-color: #669933 !default;
$rqb-dnd-hover-border-bottom-style: dashed !default;
$rqb-dnd-hover-border-bottom-width: 2px !default;

// Branches
$rqb-branch-indent: $rqb-spacing !default;
$rqb-branch-color: $rqb-border-color !default;
$rqb-branch-width: $rqb-border-width !default;
$rqb-branch-radius: $rqb-border-radius !default;
$rqb-branch-style: $rqb-border-style !default;

$rqb-invalid-background: #2b292898;
$rqb-invalid-border-color: red;
$rqb-invalid-border-width: 1px;
$rqb-invalid-padding: 2px;

// Default styles
.ruleGroup {
  display: flex;
  flex-direction: column;
  gap: $rqb-spacing;
  padding: $rqb-spacing;
  border-color: $rqb-border-color;
  border-style: $rqb-border-style;
  border-radius: $rqb-border-radius;
  border-width: $rqb-border-width;
  background: $rqb-background-color;

  .ruleGroup-body {
    display: flex;
    flex-direction: column;
    gap: $rqb-spacing;

    &:empty {
      display: none;
    }
  }

  .ruleGroup-header,
  .rule {
    display: flex;
    gap: $rqb-spacing;
    align-items: center;
  }

  .rule {
    .rule-value {
      .rule-value-list-item:not(:first-of-type) {
        margin-left: $rqb-spacing;
      }
    }
  }
}

// #region Drag-and-drop

// Hover styles
[data-inlinecombinators='disabled'] {
  .dndOver {
    &.rule,
    &.ruleGroup-header {
      border-bottom-width: $rqb-dnd-hover-border-bottom-width;
      border-bottom-style: $rqb-dnd-hover-border-bottom-style;
      border-bottom-color: $rqb-dnd-hover-border-bottom-color;
      padding-bottom: $rqb-spacing;

      &.dndCopy {
        border-bottom-color: $rqb-dnd-hover-copy-border-bottom-color;
      }
    }
  }
}
[data-inlinecombinators='enabled'] {
  .dndOver {
    &.rule:last-child,
    &.ruleGroup-header,
    &.rule + .betweenRules,
    &.betweenRules {
      border-bottom-width: $rqb-dnd-hover-border-bottom-width;
      border-bottom-style: $rqb-dnd-hover-border-bottom-style;
      border-bottom-color: $rqb-dnd-hover-border-bottom-color;
      padding-bottom: $rqb-spacing;

      &.dndCopy {
        border-bottom-color: $rqb-dnd-hover-copy-border-bottom-color;
      }
    }
  }
}

// Drag styles
.ruleGroup,
.rule {
  &.dndDragging {
    opacity: 0.5;
  }

  .queryBuilder-dragHandle {
    cursor: move;
  }
}

// When drag-and-drop is disabled, hide all drag handles
[data-dnd='disabled'] .queryBuilder-dragHandle {
  display: none;
}

// #endregion

// #region Branches

.queryBuilder-branches {
  .ruleGroup-body {
    margin-left: calc(2 * #{$rqb-branch-indent});
  }

  .rule,
  .ruleGroup .ruleGroup {
    position: relative;

    &::before,
    &::after {
      content: '';
      width: $rqb-branch-indent;
      left: calc(-#{$rqb-branch-indent} - #{$rqb-branch-width});
      border-color: $rqb-branch-color;
      border-style: $rqb-branch-style;
      border-radius: 0;
      position: absolute;
    }

    &::before {
      top: -$rqb-spacing;
      height: calc(50% + #{$rqb-spacing});
      border-width: 0 0 $rqb-branch-width $rqb-branch-width;
    }

    &:last-child::before {
      border-bottom-left-radius: $rqb-branch-radius;
    }

    &::after {
      top: 50%;
      height: 50%;
      border-width: 0 0 0 $rqb-branch-width;
    }

    &:last-child::after {
      display: none;
    }
  }

  .ruleGroup .ruleGroup {
    &::before,
    &::after {
      left: calc(calc(-#{$rqb-branch-indent} - #{$rqb-branch-width}) - #{$rqb-border-width});
    }

    &::before {
      top: calc(-#{$rqb-spacing} - #{$rqb-border-width});
      height: calc(50% + #{$rqb-spacing} + #{$rqb-border-width});
    }

    &::after {
      height: calc(50% + #{$rqb-border-width});
    }
  }

  .betweenRules {
    position: relative;

    &::before {
      content: '';
      width: $rqb-branch-indent;
      left: calc(-#{$rqb-branch-indent} - #{$rqb-branch-width});
      border-color: $rqb-branch-color;
      border-style: $rqb-branch-style;
      border-radius: 0;
      position: absolute;
      top: -$rqb-spacing;
      height: calc(100% + #{$rqb-spacing});
      border-width: 0 0 0 $rqb-branch-width;
    }
  }
}

// Validation

.queryBuilder {

  .queryBuilder-invalid:before {
    left: calc(-0.5rem - $rqb-invalid-padding);
    top: calc(-0.5rem - $rqb-invalid-border-width);
  }
  
  .queryBuilder-invalid:after {
    left: calc(-0.5rem - $rqb-invalid-padding);
    top: calc(50% + $rqb-invalid-border-width);
    height: calc(50% + $rqb-invalid-border-width);
  }
  // Invalid groups
  .ruleGroup.queryBuilder-invalid {
    background-color: $rqb-invalid-background; 
    border-color: $rqb-invalid-border-color;

    // Bold the +Rule button if the group has no child rules or groups
    .ruleGroup-addRule {
      font-weight: bold !important;
    }

  }

  

  // Invalid rules
  .rule.queryBuilder-invalid {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: $rqb-invalid-background; 
    border-color: $rqb-invalid-border-color;
    padding: $rqb-invalid-padding;
    border-width: $rqb-invalid-border-width;
  
    // .rule-value.ant-input {
    //   border-color: $rqb-invalid-border-color;
    //   border-width: $rqb-invalid-border-width;
    // }
    .rule-value {
      .ant-select-selector {
        border-width: $rqb-invalid-border-width;
        border-color: $rqb-invalid-border-color;
      }
      
    }
  }
}

// #endregion
