.wallet-footer { 
	border-top: 1px solid #e8e8e8;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: white;
	text-align: center;
	display: flex;}

.wallet-footer-user {
	margin-right: 30px;
	flex-direction: row;
	margin-bottom: 15px;
}

.wallet-footer-date {
	margin-right: 30px;
	flex-direction: row;
}
