// base_style.styl

.calendar {
    border-radius: 5px;
    padding: 30px;
}
.calendar-month {
    width: 300px;
    user-select: none;
}
.calendar-month_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.calendar-week {
    display: flex;
    justify-content: space-between;
}
.calendar-days_of_week {
    display: flex;
    justify-content: space-between;
}
.calendar-week-day {
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 30px;
    opacity: 0.5;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
}
.calendar-days_of_week_day {
    display: inline-block;
    width: 40px;
    text-align: center;
    line-height: 30px;
    opacity: 0.5;
}
.calendar-week-day.is-selectable {
    cursor: pointer;
}
.calendar-week-day.is-disabled {
    background: #f5f5f5;
}
.calendar-week-day.is-current_month {
    opacity: 1;
}
.calendar-week-day.is-selected {
    background: #008000;
}
.calendar-week-day.is-selected.is-start_selection {
    background: #f00;
}
.calendar-week-day.is-selected.is-end_selection {
    background: #00f;
}
.calendar-header_button.is-prev {
    &:before {
        content: '<';
    }
}
.calendar-header_button.is-next {
    &:before {
        content: '>';
    }
}



// date_picker.styl

@color_1: #303030;
@color_2: #acb3bb;
@color_3: #c3c3c3;
@color_4: rgba(172,179,187,0.85);
@color_5: #404040;
@color_6: #fff;
@color_7: #e4e7ea;
@font_family_1: 'PT Sans', sans-serif;
@border_color_1: #4a90e2;

.date_picker {
    padding: 30px;
    border-radius: 5px;
    border: 1px solid #e4e7ea;
    color: @color_1;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    flex-basis: 250px;
    max-width: 250px;
    font-family: @font_family_1;
}
.date_picker-month_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.date_picker-month_header_wrapper {
    text-align: center;
    margin: 0 auto 25px;
}
.date_picker-month_header_title {
    font-size: 20px;
}
.date_picker-header_button {
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    border: 1px solid #e4e7ea;
    transition: border 0.1s ease-in, color 0.1s ease-in;
    position: relative;
    height: 35px;
    width: 35px;
    &:focus {
        outline: 0;
    }
}
.date_picker-header_button.is-disabled {
    cursor: default;
    .date_picker-header_button.is-next {
        color: @color_2;
    }
    .date_picker-header_button.is-prev {
        color: @color_2;
    }
}
.date_picker-header_button.is-next {
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: fill 0.1s ease-in;
        color: @color_1;
    }
    &:after {
        content: '>';
    }
}
.date_picker-header_button.is-prev {
    &:before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: fill 0.1s ease-in;
        color: @color_1;
        content: '<';
    }
}
.date_picker-calendar_wrapper.is-shortcut_hover_outrange {
    .date_picker-header_button.is-next {
        border-color: @border_color_1;
        background: #e7f5ff;
    }
    .date_picker-header_button.is-prev {
        border-color: @border_color_1;
        background: #e7f5ff;
    }
}
.date_picker-days_of_week {
    font-size: 13px;
    display: flex;
    color: @color_3;
    text-align: center;
    margin-bottom: 25px;
}
.date_picker-days_of_week_day {
    flex-basis: 15%;
}
.date_picker-week {
    display: flex;
    margin-bottom: 8px;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.date_picker-week-day {
    height: 34px;
    width: 34px;
    vertical-align: middle;
    font-size: 15px;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 15%;
    background: #fff;
    position: relative;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    border: none;
    outline: 0;
    &:first-of-type {
        &:hover {
            border-radius: 50%;
        }
        border-radius: 50% 0 0 50%;
    }
    &:last-of-type {
        &:hover {
            border-radius: 50%;
        }
        border-radius: 0 50% 50% 0;
    }
}
.date_picker-week-day.is-prev_month {
    color: @color_4;
}
.date_picker-week-day.is-next_month {
    color: @color_4;
}
.date_picker-week-day.is-today {
    font-weight: 700;
}
.date_picker-week-day.is-selected {
    background: #e3effc;
    color: @color_5;
    position: relative;
    z-index: 1;
    border-radius: 0;
    &:first-of-type {
        border-radius: 50% 0 0 50%;
    }
    &:last-of-type {
        border-radius: 0 50% 50% 0;
    }
    &::before {
        background: #3863a0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
        border-radius: 50%;
        color: @color_6;
    }
    &:hover {
        &::before {
            content: '';
            box-shadow: inset 0 0 1px 0 #3863a0;
            background: #e3effc;
            color: @color_5;
        }
    }
}
.date_picker-calendar_wrapper.is-shortcut_hovered {
    .date_picker-week-day.is-selected {
        &::before {
            background: #4a90e2;
        }
    }
}
.date_picker-week-day.is-selected.is-start_selection.date_picker-week-day.is-selected {
    &:hover {
        &::before {
            background: #3863a0;
        }
    }
}
.date_picker-week-day.is-selected.is-end_selection.date_picker-week-day.is-selected {
    &:hover {
        &::before {
            background: #3863a0;
        }
    }
}
.date_picker-week-day.is-selected.is-start_selection {
    background: #e3effc;
    color: @color_6;
    border-radius: 50% 0 0 50%;
    &::before {
        content: '';
    }
    &:last-of-type {
        border-radius: 50%;
    }
}
.date_picker-week-day.is-selected.is-end_selection {
    background: #e3effc;
    color: @color_6;
    border-radius: 0 50% 50% 0;
    &::before {
        content: '';
    }
    &:first-of-type {
        border-radius: 50%;
    }
}
.date_picker-week-day.is-selected.is-end_selection.is-start_selection {
    border-radius: 50%;
}
.date_picker-week-day.is-selectable {
    cursor: pointer;
    position: relative;
    &:before {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &:hover {
        &:not(.is-selected) {
            z-index: 1;
            box-shadow: inset 0 0 1px 0 #3863a0;
        }
    }
}
.date_picker-week-day.is-not_selectable {
    color: @color_7;
}
.date_picker-notice {
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 15px;
    border-radius: 5px;
    padding: 15px;
    color: @color_6;
    background: #ff6c7e;
    max-width: 238px;
}
