@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: Uni Sans Bold;
        font-weight: 700;
        src: url('./assets/fonts/Uni Sans Bold.ttf') format("ttf");
    }
}
